import InformationPopover from '../../../../shared/InformationPopover';

export default function BlockOrUnblockToggleLabel() {
    return (
        <div className="blockedChargingStationToggleLabel">
            <p className="dashboard-island-charging-station-blocked-toggle-text">Blocked</p>
            <InformationPopover infoHeader="Blocked charging station toggle">
                <p>A blocked charging station will automatically stop any transaction that is started at that station.</p>
            </InformationPopover>
        </div>
    );
}
