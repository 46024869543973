import { useQuery } from 'react-query';
import { getUserCompanyLeaderboardScores } from '../../service/Api';
import ApiError from '../shared/ApiError';
import LoadingSpinner from '../shared/LoadingSpinner';
import { Table } from 'react-bootstrap';
import DisplayLocation from '../../model/DisplayLocation';

interface UserCompanyWallOfFameProps {
    location: DisplayLocation;
}

export default function UserCompanyWallOfFame(props: UserCompanyWallOfFameProps) {
    const {
        data: leaderBoard,
        isLoading: isLeaderBoardLoading,
        error: leaderBoardError
    } = useQuery(['userCompanyWallOfFame', props.location.name], () => getUserCompanyLeaderboardScores(props.location.name), {
        refetchOnWindowFocus: false
    });

    if (isLeaderBoardLoading) {
        return <LoadingSpinner />;
    } else if (leaderBoardError) {
        return <ApiError error={leaderBoardError} />;
    }

    return (
        <Table striped bordered>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Rank</th>
                </tr>
            </thead>
            <tbody>
                {leaderBoard?.data?.map((userScore: any) => (
                    <tr key={`${userScore.firstname}-${userScore.lastname}`}>
                        <td>
                            {userScore.firstname} {userScore.lastname}
                        </td>
                        <td>{userScore.rank === -1 ? '-' : userScore.rank + 1}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
