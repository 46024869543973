import { useEffect, useState } from 'react';
import { Button, Form, InputGroup, ListGroup, Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getPoolSuggestions, getUserByEmail, sendAddRequest } from '../../service/Api';
import { AxiosResponse, isAxiosError } from 'axios';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';
import UserPool from '../../model/UserPool';
import UserDto from '../../model/user/UserDto';

interface AddUserModalProps {
    show: boolean;
    onHide: () => void;
    refetchPool: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<UserPool[], unknown>>;
}

export default function AddUserModal(props: AddUserModalProps) {
    type AddRequest = {
        email: string;
    };

    const { register, handleSubmit, watch, setValue, reset, getValues } = useForm<AddRequest>();
    const [autoComplete, setAutoComplete] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [fetchSuggestionsEnabled, setFetchSuggestionsEnabled] = useState(true);
    const [addRequestError, setAddRequestError] = useState<string | null>(null);
    const [suggestions, setSuggestions] = useState<string[]>([]);

    const {
        data: poolSuggestions,
        isLoading: poolSuggestionsLoading,
        error: poolSuggestionsError,
        refetch: poolSuggestionsRefetch
    } = useQuery(['poolSuggestions'], () => getPoolSuggestions(), {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (poolSuggestionsLoading || poolSuggestionsError) {
            setSuggestions([]);
            return;
        }
        if (poolSuggestions && poolSuggestions.data?.length > 0) {
            setSuggestions(poolSuggestions.data);
        }
    }, [poolSuggestionsLoading, poolSuggestionsError, poolSuggestions]);

    useEffect(() => {
        if (props.show) {
            setAddRequestError(null);
            poolSuggestionsRefetch();
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    const handleAddRequest: SubmitHandler<AddRequest> = async (data) => {
        try {
            setAddRequestError(null);
            setSuggestions([]);
            await sendAddRequest(data.email);
            props.onHide();
            props.refetchPool();
        } catch (error) {
            if (isAxiosError(error) && error.response?.data?.detail) {
                setAddRequestError(error.response?.data?.detail);
            } else {
                setAddRequestError('Something unexpected went wrong, please try again later.');
            }
        }
    };

    const fetchAutocomplete = async (email: string) => {
        try {
            const response: AxiosResponse<UserDto[]> = await getUserByEmail(email);
            const users: UserDto[] = response.data;
            const emails: string[] = users.map((user: UserDto): string => user.contactEmail);
            setAutoComplete(emails);
        } catch (error) {
            console.error('Error fetching autocomplete', error);
        }
    };

    const email = watch('email');

    useEffect(() => {
        const emailPattern = /^[a-zA-Z]+[.@].*$/;
        if (fetchSuggestionsEnabled && emailPattern.test(email)) {
            fetchAutocomplete(email);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
            setAutoComplete([]);
        }
    }, [email, fetchSuggestionsEnabled]);

    const handleSuggestionClick = (suggestion: string) => {
        setValue('email', suggestion);
        setShowSuggestions(false);
        setFetchSuggestionsEnabled(false);
    };

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>Add someone to your pool</Modal.Header>
            <Form onSubmit={handleSubmit(handleAddRequest)}>
                <Modal.Body>
                    <h2 className={'h6'}>Add someone to your pool:</h2>
                    <Form.Group style={{ textAlign: 'left' }} className="mb-3">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="off"
                            placeholder={'Enter email of person you want to add'}
                            {...register('email')}
                            onFocus={() => setFetchSuggestionsEnabled(true)}
                        />
                        {showSuggestions && autoComplete.length > 0 && (
                            <ListGroup className="email-suggestions">
                                {autoComplete.map((suggestion, index) => (
                                    <ListGroup.Item key={index} action onClick={() => handleSuggestionClick(suggestion)}>
                                        {suggestion}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                        {addRequestError && <span style={{ color: 'red' }}>{addRequestError}</span>}
                    </Form.Group>

                    {suggestions.length == 0 ? (
                        <></>
                    ) : (
                        <div>
                            <h2 className={'h6'}>Suggestions:</h2>
                            {suggestions.map((email) => (
                                <InputGroup style={{ marginTop: '0.4em' }}>
                                    <Form.Control style={{ overflowY: 'auto' }} value={email} disabled />
                                    <Button
                                        style={{ width: 'fit-content' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleAddRequest({ email: email });
                                        }}
                                    >
                                        Add
                                    </Button>
                                </InputGroup>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="secondary" onClick={props.onHide}>
                        Cancel
                    </Button>
                    <Button disabled={getValues('email') === undefined || getValues('email').length === 0} type="submit" style={{ width: 'auto' }}>
                        Send Request
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
