import { Tab, Tabs } from 'react-bootstrap';
import '../../styling/leaderBoard.css';
import { useState } from 'react';
import CompaniesWallOfFame from './CompaniesWallOfFame';
import UserCompanyWallOfFame from './UserCompanyWallOfFame';
import UserPoolWallOfFame from './UserPoolWallOfFame';
import Top3Podium from './Top3Podium';
import FullWallOfFame from './FullWallOfFame';
import useCheckIfAdminOrManager from '../../hooks/useCheckIfAdminOrManager';
import useGetUserInfo from '../../hooks/useGetUserInfo';
import DisplayLocation from '../../model/DisplayLocation';
import { capitalize } from '../../utils/StringUtils';

interface LocationWallOfFameProps {
    location: DisplayLocation;
}

export default function LocationWallOfFame(props: LocationWallOfFameProps) {
    const isAdminOrManager = useCheckIfAdminOrManager();
    const userInfo = useGetUserInfo();

    const [tab, setTab] = useState('companies');

    const getUserCompany = () => {
        try {
            const company = userInfo.email.split('@')[1].split('.')[0].toLowerCase();
            return capitalize(company);
        } catch (e) {
            return 'Your company';
        }
    };
    return (
        <div style={{ margin: '1em' }}>
            <Top3Podium location={props.location} />

            <Tabs id="leaderboardTabs" activeKey={tab} onSelect={(k) => setTab(k ?? 'companies')} className={'leaderboardTabs'}>
                <Tab title="All companies" eventKey="companies">
                    {tab === 'companies' ? <CompaniesWallOfFame location={props.location} /> : <></>}
                </Tab>
                <Tab title={getUserCompany()} eventKey="userCompany">
                    {tab === 'userCompany' ? <UserCompanyWallOfFame location={props.location} /> : <></>}
                </Tab>
                <Tab title="Pool" eventKey="pool">
                    {tab === 'pool' ? <UserPoolWallOfFame location={props.location} /> : <></>}
                </Tab>
                {isAdminOrManager ? (
                    <Tab title="Full wall of fame" eventKey="full">
                        {tab === 'full' ? <FullWallOfFame location={props.location} /> : <></>}
                    </Tab>
                ) : (
                    <></>
                )}
            </Tabs>
        </div>
    );
}
