import { Button, Modal } from 'react-bootstrap';
import { VerificationModalProps } from '../../props/VerificationModalProps';

export default function VerificationModal(props: VerificationModalProps) {
    const { show, title, content, onHide, onCancel, onProceed } = props;
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{content}</Modal.Body>
            <Modal.Footer className="d-flex justify-content-center" style={{ flexWrap: 'nowrap' }}>
                <Button variant="secondary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onProceed}>
                    Proceed
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
