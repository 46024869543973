import { useQuery } from 'react-query';
import { getAllCompaniesLeaderboardScores } from '../../service/Api';
import ApiError from '../shared/ApiError';
import LoadingSpinner from '../shared/LoadingSpinner';
import { Table } from 'react-bootstrap';
import DisplayLocation from '../../model/DisplayLocation';
import { capitalize } from '../../utils/StringUtils';

interface CompaniesWallOfFameProps {
    location: DisplayLocation;
}

export default function CompaniesWallOfFame(props: CompaniesWallOfFameProps) {
    const {
        data: leaderBoard,
        isLoading: isLeaderBoardLoading,
        error: leaderBoardError
    } = useQuery(['companies', props.location.name], () => getAllCompaniesLeaderboardScores(props.location.name), {
        refetchOnWindowFocus: false
    });

    if (isLeaderBoardLoading) {
        return <LoadingSpinner />;
    } else if (leaderBoardError) {
        return <ApiError error={leaderBoardError} />;
    }
    return (
        <Table striped bordered>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Rank</th>
                </tr>
            </thead>
            <tbody>
                {leaderBoard?.data?.map((companyScore: any) => (
                    <tr key={companyScore.index}>
                        <td>{capitalize(companyScore.company)}</td>
                        <td>{companyScore.index + 1}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
