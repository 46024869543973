import BlockOrUnblockVerificationModal from './BlockOrUnblockVerificationModal';
import { useState } from 'react';
import { toggleBlockOrUnblockChargingStation } from '../../../../../service/ChargingStationService';
import ChargingStation from '../../../../../model/ChargingStation';
import BlockOrUnblockToggle from './BlockOrUnblockToggle';
import BlockOrUnblockToggleLabel from './BlockOrUnblockToggleLabel';

interface BlockOrUnblockProps {
    chargingStation: ChargingStation;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    setShowMessage: React.Dispatch<React.SetStateAction<boolean>>;
    refreshChargingStations: () => Promise<void>;
}

export default function BlockOrUnblock(props: BlockOrUnblockProps) {
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const { chargingStation } = props;
    const isBlocked: boolean = chargingStation.isBlocked ?? false;

    function openVerificationModal(): void {
        setShowVerificationModal(true);
    }

    function closeVerificationModal(): void {
        setShowVerificationModal(false);
    }

    async function blockOrUnblockChargingStation(): Promise<void> {
        try {
            await toggleBlockOrUnblockChargingStation(chargingStation);
            closeVerificationModal();
            await props.refreshChargingStations();
        } catch (error) {
            closeVerificationModal();
            props.setMessage(`Failed to block/unblock charging station #${chargingStation.nr}.`);
            props.setShowMessage(true);
        }
    }

    return (
        <>
            <BlockOrUnblockToggleLabel />
            <BlockOrUnblockToggle onChange={openVerificationModal} checked={isBlocked} />
            <BlockOrUnblockVerificationModal
                chargingStation={chargingStation}
                show={showVerificationModal}
                onHide={closeVerificationModal}
                onCancel={closeVerificationModal}
                onProceed={blockOrUnblockChargingStation}
            />
        </>
    );
}
