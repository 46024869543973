import ChargingStation from '../model/ChargingStation';
import { AxiosResponse } from 'axios';
import { toggleBlockOrUnblockChargingStation as toggleBlockOrUnblock } from './Api';

export async function toggleBlockOrUnblockChargingStation(chargingStation: ChargingStation): Promise<ChargingStation> {
    try {
        const response: AxiosResponse<ChargingStation> = await toggleBlockOrUnblock(chargingStation.key);
        return response.data;
    } catch (error) {
        console.error('Error blocking/unblocking charging station:', error);
        throw error;
    }
}
