import { Form } from 'react-bootstrap';

interface BlockOrUnblockToggleProps {
    onChange: () => void;
    checked: boolean;
}

export default function BlockOrUnblockToggle(props: BlockOrUnblockToggleProps) {
    const { onChange, checked } = props;
    return (
        <div className="blockedChargingStationToggle">
            <Form.Check type="switch" id="blockedChargingStationToggle" onChange={onChange} checked={checked} />
        </div>
    );
}
