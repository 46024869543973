import VerificationModal from '../../../../shared/VerificationModal';
import ChargingStation from '../../../../../model/ChargingStation';
import { capitalize } from '../../../../../utils/StringUtils';

interface BlockOrUnblockVerificationModalProps {
    chargingStation: ChargingStation;
    show: boolean;
    onHide: () => void;
    onCancel: () => void;
    onProceed: () => void;
}

export default function BlockOrUnblockVerificationModal(props: BlockOrUnblockVerificationModalProps) {
    const { chargingStation, show, onHide, onCancel, onProceed } = props;
    const blockOrUnblock: 'unblock' | 'block' = chargingStation.isBlocked ? 'unblock' : 'block';
    const title = `${capitalize(blockOrUnblock)} charging station`;
    const content = `Are you sure you want to ${blockOrUnblock} charging station #${chargingStation.nr}?`;
    return <VerificationModal show={show} title={title} content={content} onHide={onHide} onCancel={onCancel} onProceed={onProceed} />;
}
