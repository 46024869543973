import ChargingStation from '../../../model/ChargingStation';
import CsNumber from './dashboardItemValues/CsNumber';
import Initials from './dashboardItemValues/Initials';
import StationNotifications from './dashboardItemValues/StationNotifications';
import Status from './dashboardItemValues/Status';
import CommonDashboardProps from '../../../props/CommonDashboardProps';
import TransactionButton from './dashboardItemValues/TransactionButton';
import CommonDashboardTransactionProps from '../../../props/CommonDashboardTransactionProps';
import Kwh from './dashboardItemValues/Kwh';
import ChargingTime from './dashboardItemValues/ChargingTime';
import OccupationTime from './dashboardItemValues/OccupationTime';
import getStatusColours from '../../../hooks/useGetStatusColour';
import { isStationDown } from '../../../service/ChargingStationFunctions';
import { useState } from 'react';
import DisplayLocation from '../../../model/DisplayLocation';
import BlockOrUnblock from './dashboardItemValues/blockOrUnblock/BlockOrUnblock';

interface DashboarItemProps extends CommonDashboardProps, CommonDashboardTransactionProps {
    chargingStation: ChargingStation;
    isAdminOrManager: boolean;
    chargingStationLocation: DisplayLocation;
}

export default function DashboardItem(props: DashboarItemProps) {
    const [showAdminSettings, setShowAdminSettings] = useState<boolean>(false);
    const { chargingStation, isAdminOrManager, setTransactionMessage, setShowMessageModal } = props;

    async function refreshChargingStations(): Promise<void> {
        await props.refetchChargingStations();
    }

    return (
        <>
            <div
                className={`dashboard-grid-item-container ${isAdminOrManager ? 'admin' : 'user'}`}
                style={{
                    borderColor: getStatusColours(
                        chargingStation.comStatus === 'DOWN' && !isAdminOrManager
                            ? 'DOWN'
                            : chargingStation.isBlocked
                              ? 'BLOCKED'
                              : chargingStation.ocppStatus
                    ).border
                }}
            >
                <div className="userContainer">
                    <CsNumber {...props} />
                    <Initials {...props} />
                    <Status {...props} />
                    <Kwh kWh={chargingStation.kWh} slowCharging={chargingStation.slowCharging} car={chargingStation.carDto} />
                    <ChargingTime chargingTime={chargingStation.chargingTime} />
                    <OccupationTime
                        {...props}
                        occupationTime={chargingStation.occupationTime}
                        chargingTime={chargingStation.chargingTime}
                        status={chargingStation.ocppStatus}
                    />
                </div>
                {isAdminOrManager ? (
                    isStationDown(chargingStation) ? (
                        <div className="adminContainerDown">
                            <StationNotifications {...props} />
                            <BlockOrUnblock
                                chargingStation={chargingStation}
                                setMessage={setTransactionMessage}
                                setShowMessage={setShowMessageModal}
                                refreshChargingStations={refreshChargingStations}
                            />
                        </div>
                    ) : (
                        <div style={{ gridArea: 'AdminContainer' }}>
                            <div className="adminContainerUp">
                                <TransactionButton
                                    {...props}
                                    chargingStation={chargingStation}
                                    showAdminSettings={showAdminSettings}
                                    setShowAdminSettings={setShowAdminSettings}
                                />
                            </div>
                            <div className="adminContainerDown" hidden={!showAdminSettings}>
                                <StationNotifications {...props} />
                                <BlockOrUnblock
                                    chargingStation={chargingStation}
                                    setMessage={setTransactionMessage}
                                    setShowMessage={setShowMessageModal}
                                    refreshChargingStations={refreshChargingStations}
                                />
                            </div>
                        </div>
                    )
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
