import { stopTransaction } from '../../../service/Api';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { AxiosResponse } from 'axios';
import VerificationModal from '../../shared/VerificationModal';

interface StopTransactionVerificationModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setTransactionMessage: React.Dispatch<React.SetStateAction<string>>;
    setShowMessageModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetchChargingStations: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>;
    location: string;
    nr: number;
}

export default function StopTransactionVerificationModal(props: StopTransactionVerificationModalProps) {
    const handleClose = () => props.setShow(false);

    const handleStopTransaction = async () => {
        try {
            props.setShow(false);
            await stopTransaction(props.location, props.nr);
            props.refetchChargingStations();
        } catch (error) {
            props.setTransactionMessage('Could not stop transaction, something went wrong.  Please try again later');
            props.setShow(false);
            props.setShowMessageModal(true);
        }
    };

    return (
        <VerificationModal
            show={props.show}
            title="Stop Transaction"
            content="Are you sure you want to stop this transaction?"
            onHide={handleClose}
            onCancel={handleClose}
            onProceed={handleStopTransaction}
        />
    );
}
