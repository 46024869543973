import { capitalize } from '../utils/StringUtils';

const mapStatus = (status: string) => {
    switch (status) {
        case 'SUSPENDED_EV':
            return 'Ready for pickup';
        case 'SUSPENDED_EVSE':
            return 'Paused';
        case 'BLOCKED':
            return 'Blocked';
        default:
            return capitalize(status.toLowerCase().replaceAll('_', ' '));
    }
};

export { mapStatus };
